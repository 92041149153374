<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="241.183"
		height="160.414"
		viewBox="0 0 241.183 160.414"
	>
		<g transform="translate(0 100.189)">
			<path
				fill="#072a3d"
				d="M496.492,540.97v22.536H483.333V503.281h24.279q11.677,0,11.677,10.416v16.856q0,8.333-7.413,10.038l10.379,22.916H508.075l-9.266-22.536Zm0-27.746v18.181h7.136a2.675,2.675,0,0,0,1.853-.52,2.32,2.32,0,0,0,.556-1.752V515.591a2.112,2.112,0,0,0-2.41-2.367Z"
				transform="translate(-483.333 -503.281)"
			/>
			<path
				fill="#072a3d"
				d="M585.34,563.506,583.3,552.617H569.864l-2.039,10.889H554.759L568.1,503.281h16.866l13.715,60.225Zm-13.622-20.737h9.638L576.536,517.3Z"
				transform="translate(-512.659 -503.281)"
			/>
			<path
				fill="#072a3d"
				d="M657.448,503.281H670.7V553q0,10.51-11.769,10.51H646.512q-11.676,0-11.676-10.51V503.281h13.252V551.2a2.111,2.111,0,0,0,2.41,2.367h4.448q2.5,0,2.5-2.367Z"
				transform="translate(-545.536 -503.281)"
			/>
			<path
				fill="#072a3d"
				d="M739.863,503.281v9.943l-21.035,40.34h20.48v9.942h-34.2v-9.942l21.037-40.34H706.224v-9.943Z"
				transform="translate(-574.39 -503.281)"
			/>
			<path
				fill="#072a3d"
				d="M786.91,553.564h18.9v9.942H773.658V503.281h31.415v9.943H786.91V527.9h15.476v9.942H786.91Z"
				transform="translate(-602.533 -503.281)"
			/>
			<path
				fill="#072a3d"
				d="M851.212,553.564h18.9v9.942H837.96V503.281h31.415v9.943H851.212V527.9h15.476v9.942H851.212Z"
				transform="translate(-628.934 -503.281)"
			/>
		</g>
		<path
			fill="#184b76"
			d="M700.52,393.073V356.237l-40.809-23.1-42.22,23.075v13.735l42.182-23.076L688.521,363.3v8.512l-28.835-16.792L617.493,380v36.411l12.1-7.169V386.206l30.083-17.374Z"
			transform="translate(-538.415 -333.141)"
		/>
		<path
			fill="#184b76"
			d="M678.713,407.6l-11.878,7.35,53.946,31.922V432.709Z"
			transform="translate(-558.674 -363.754)"
		/>
	</svg>
</template>
